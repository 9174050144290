//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default{
		props:{
			isShow: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: '提示'
			}
		},
		data(){
			return{
				
			}
		},
		created(){
			
		},
		methods:{
			//关闭dialog
			close() {
				this.$emit('close', false)
			},
			handleConfirm(){
				this.$emit('handleConfirm', false)
			},
		}
	}
