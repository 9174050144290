//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import Picker from 'vux/src/components/picker';
	export default {
		components: {
			Picker
		},
		model: {
			prop: '_value',
			event: "valueListener"
		},
		props: {
			//################# 公共属性  ################
			_value: { //v-model
				type: String,
				default: ''
			},
			type: { //选择器类型
				type: String,
				default: 'picker' // picker=列选择器  | date=日期选择器
			},
			label: { //label
				type: String,
				default: ''
			},
			verify: { //是否必填样式
				type: Boolean,
				default: false
			},
			hasIcon: { //是否有icon-right
				type: Boolean,
				default: true
			},
			red: { //文本颜色是否标红
				type: Boolean,
				default: false
			},
			//################## 当且仅当 type==picker 有效  #################
			title: { //title
				type: String,
				default: ''
			},
			list: { //选择列表数据
				type: Array,
				default: () => {
					return []
				}
			},
			columns: { //选择的列数
				type: Number,
				default: 1
			},
			modal: { //是否启用其定义遮罩层
				type: Boolean,
				default: true // true == 自定义遮罩层  |  false == 采用mint-popup 自带的modal遮罩
			},
			//################### 当且仅当 type==date 有效  #################
			startDate: null, //任何类型
			endDate: null, //任何类型
			cancelText: { //取消文本
				type: String,
				default: '取消'
			},
			confirmText: { //确定文本
				type: String,
				default: '确定'
			},
			clearText: { //清除文本  默认为空=没有清除功能
				type: String,
				default: ''
			},
			format: { //日期格式
				type: String,
				default: 'YYYY-MM-DD' //YYYY-MM-DD HH:mm  or  YYYY-MM-DD A
			},
			minuteList: {
				type: Array,
				default: () => {
					return [
						'00', '02', '03', '04', '05', '06', '07', '08', '09',
						'10', '12', '13', '14', '15', '16', '17', '18', '19',
						'20', '22', '23', '24', '25', '26', '27', '28', '29',
						'30', '32', '33', '34', '35', '36', '37', '38', '39',
						'40', '42', '43', '44', '45', '46', '47', '48', '49',
						'50', '52', '53', '54', '55', '56', '57', '58', '59'
					];
				}
			},
			maxHour: {
				default: 23
			},
			minHour: {
				default: 0
			},
			computeHoursFunction: {
				type:Function
			}
		},
		watch: {
			_value() {
				this.syncVal();
			},
			pickerVal() {
				// picker 切换值变化
				if(this.isShowPopup) {
					this.$emit("onChange", this.pickerVal.join('--'));
				}
				this.$emit("valueListener", this.pickerVal.join('--'));
			},
			dateVal() {
				this.$emit("valueListener", this.dateVal)
			},
			isShowPopup() {
				this.isShowPopup && this.appendChildToBody();
			}
		},
		data() {
			return {
				pickerVal: [],
				dateVal: '',
				isShowPopup: false
			}
		},
		created() {
			this.syncVal();
		},
		methods: {
			//响应数据
			syncVal() {
				const actions = new Map([
					['picker', () => {
						// 【--】原因是联级选择，采用-隔断多个值，可能会跟单个值包含-冲突！所以联级采用--隔开
						this.pickerVal = this._value.split('--');
					}],
					['date', () => {
						this.dateVal = this._value;
					}],
					['default', () => {
						this.pickerVal = this._value.split('--');
					}]
				]);
				let action = actions.get(this.type) || actions.get('default');
				action.call(this);
			},
			//打开picker选择器
			openPicker() {
				const actions = new Map([
					['picker', () => {
						this.isShowPopup = true;
					}],
					['date', () => {
						this.showDateTime();
					}],
					['default', () => {
						this.isShowPopup = true;
					}]
				]);
				let action = actions.get(this.type) || actions.get('default');
				action.call(this);
			},
			//确定：需要获取什么参数待续...
			confirm() {
				this.isShowPopup = false;
				this.$emit('confirm', this.pickerVal);
			},
			//取消：需要获取什么参数待续...
			clear() {
				this.isShowPopup = false;
				this.pickerVal = [];
				this.$emit('clear', "clear");
			},
			//日期选择器
			showDateTime() {
				this.setRouteListeners('closeVuxDatetime'); //安卓返回键
				this.$vux.datetime.show({
					value: this.dateVal, // 其他参数同 props
					format: this.format,
					yearRow: "{value}年",
					monthRow: "{value}月",
					dayRow: "{value}日",
					startDate: this.startDate,
					endDate: this.endDate,
					cancelText: this.cancelText,
					confirmText: this.confirmText,
					clearText: this.clearText,
					minuteList: this.minuteList, //自定义分钟列表
					minHour: this.minHour,
					maxHour: this.maxHour,
					computeHoursFunction: this.computeHoursFunction,
					onHide: function() {
						this.removeRouteListeners('closeVuxDatetime'); //安卓返回键
					}.bind(this),
					onConfirm: function(val) {
						this.dateVal = val;
					}.bind(this),
					onClear: function() {
						this.dateVal = '';
					}.bind(this)
				})
			},
			//将mt-popup和遮罩shade添加至body
			appendChildToBody() {
				this.$nextTick(() => {
					document.body.appendChild(this.$refs.popupBox.$el);
					document.body.appendChild(this.$refs.shade);
				})
			}
		}
	}
