//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Video from "video.js";
import "video.js/dist/video-js.css";
import './custom-theme.css'

export default {
	props: {
		type: {
			type: String,
			default: 'video/mp4'
		},
		poster: {
			type: String,
			default: 'https://spkg.smartmice.cn/resources/images/oceans.png'
		},
		videoUrl: {
			type: String,
			default: 'https://spkg.smartmice.cn/resources/video/oceans.mp4'
		}
	},
	computed: {},
	data() {
		return {};
	},
	created() {},
	mounted() {
		this.initVideo();
	},
	methods: {
		initVideo() {
			//初始化视频方法
			Video("my-player", {
				//确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
				controls: true,
				//封面
				// poster: "./oceans.png",
				//自动播放属性,muted:静音播放 play:手动播放按钮  any:
				autoplay: false,
				//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
				preload: "auto",
				//设置视频播放器的显示宽度（以像素为单位）
				width: "100%",
				//设置视频播放器的显示高度（以像素为单位）
				height: "400px"
			});
		}
	}
};
