//
//
//
//
//
//
//
//

	export default {
        props: {
            list: {
                type: Array,
                default: function() { return [] }
            }
        },
		data() {
			return {

			}
		},
		methods: {

		}
	}
