//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	/*
	 带有搜索框的头部
	 * */
	export default {
		model: {
			prop: '_keyword',
			event: "keywordListener"
		},
		props: {
			_keyword: {
				type: String,
				default: ""
			},
			placeholder: {
				type: String,
				default: "请输入关键词搜索"
			},
			hasLeft: {
				type: Boolean,
				default: false
			},
			hasRight: {
				type: Boolean,
				default: true
			},
			// 是否有搜索框
			hasSearch: {
				type: Boolean,
				default: true
			},
			title: {
				type: String,
				default: "会议列表"
			}
		},
		computed: {
			style() {
				let count = 0;
				this.hasLeft ? count : count++;
				this.hasRight ? count : count++;
				//${0.44*count}rem
				return {
					width: `calc(100% - ${this.leftBtnWidth + this.rightBtnWidth}px)`,
					'padding-left': this.hasLeft ? '0rem' : '0.15rem',
					'padding-right': this.hasRight ? '0rem' : '0.2rem',
					left: `${this.leftBtnWidth}px`
				}
			}
		},
		watch: {
			_keyword() {
				this.keyword = this._keyword;
			},
			keyword() {
				this.$emit("keywordListener", this.keyword);
			}
		},
		data() {
			return {
				keyword: "",
				leftBtnWidth: 0,
				rightBtnWidth: 0
			}
		},
		created() {
			this.getBtnOffsetWidth();
		},
		methods: {
			getBtnOffsetWidth(){
				this.$nextTick(() => {
					setTimeout(() => {
						const leftBtn = document.getElementsByClassName("iHeader_left")[0];
						const rightBtn =  document.getElementsByClassName("iHeader_right")[0];
						if(leftBtn) {
							this.leftBtnWidth = leftBtn.clientWidth;
						}
						if(rightBtn) {
							this.rightBtnWidth = rightBtn.offsetWidth;
						}
					},5)
				})
			},
			clickLeft(){
				this.$emit('clickLeft')
			},
			clickRight() {
				this.$emit('clickRight')
			},
			search() {
				this.$emit("search", this.keyword);
			},
			resetSearch(){
				this.keyword = "";
			}
		}
	}
