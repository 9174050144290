//
//
//
//
//
//
//
//
//

	export default {
		props: {
			title: {
				type: String,
				default: "请选择"
			},
			hasLeft: {
				type: Boolean,
				default: true
			},
			hasRight: {
				type: Boolean,
				default: true
			},
			leftTxt: {
				type: String,
				default: "返回"
			},
			rightTxt: {
				type: String,
				default: "确定"
			},
			smHleftEvent: {
				type: Function,
				default: new Function
			},
			smHrightEvent: {
				type: Function,
				default: new Function
			}
		},
		data() {
			return {
				
			}
		},
		created() {
			
		}
	}
