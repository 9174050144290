//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			}
		},
		data() {
			return {
				isShow: false
			}
		},
		methods: {
			//关闭
			close() {
				this.isShow = false;
			}
		}
	}
