//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iNodata from '../iNodata';

export default {
	components: {
		iNodata
	},
	props: {
		allLoaded: {
			type: Boolean,
			default: false
		},
		topHeight: {
			type: Number,
			default: 0
		},
		bottomHeight: {
			type: Number,
			default: 0
		},
		pageIndex: {
			type: Number,
			default: 1
		},
		pageSize: {
			type: Number,
			default: 10
		},
		dataList: {
			type: Array,
			default: () => { return [] }
		},
		loadData: {
			type: Function,
			default: new Function
		},
		hasIcon: {
			type: Boolean,
			default: true
		},
		noDataTxt: {
			type: String,
			default: ''
		}
	},
	watch: {
		pageIndex() {
			this.PageIndex = this.pageIndex;
		}
	},
	data() {
		return {
			bottomStatus: "",
			topStatus: "",
			PageIndex: this.pageIndex,
			onSuccess: false
		};
	},
	computed: {
		scrollStyle() {
			return {
				height: `${this.iStorage.get('_screen_height') - this.topHeight - this.bottomHeight}px`,
				top: `${this.topHeight}px`
			}
		},
		contentStyle() {
			return {
				"min-height": `${this.iStorage.get('_screen_height') - this.topHeight - this.bottomHeight}px`
			}
		}
	},
	methods: {
		//父组件通知结束上拉下拉刷新状态
		scrollListener() {
			this.onSuccess = true;
			setTimeout(()=>{
				this.onSuccess = false;
				this.$nextTick(() => {
					if (this.topStatus == "loading") {
						this.$refs.loadmore.onTopLoaded();
						this.topStatus = "unknown";
					}
					if (this.bottomStatus == "loading") {
						this.$refs.loadmore.onBottomLoaded();
						this.bottomStatus = "unknown";
					}
				});
			},500)
		},
		//上拉
		loadBottom() {
			this.PageIndex = this.PageIndex || this.pageIndex;
			this.PageIndex++;
			this.loadData({
				pageIndex: this.PageIndex
			});
		},
		//下拉
		loadTop() {
			this.PageIndex = 1;
			this.loadData({
				pageIndex: this.PageIndex
			});
		},
		//下拉状态变化
		handleTopChange(status) {
			this.topStatus = status;
		},
		//上拉状态变化
		handleBottomChange(status) {
			this.bottomStatus = status;
		},
		//滑动事件
		loadmoreScroll(event) {
			this.$emit("loadmoreScroll", event);
		}
	}
};
