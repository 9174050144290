//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		model: {
			prop: 'visible',
			event: "dialogListener"
		},
		props: {
			visible: { //控制弹出、关闭
				type: Boolean,
				default: false
			},
			width: {
				type: [String, Number],
				default: 70
			},
			title: { //提示
				type: String,
				default: "<p>提示</p>"
			},
			content: {
				type: String,
				default: ""
			},
			// 操作按钮
			buttons: {
				type: Array,
				default: () => [{
					txt: "我知道了",
					code: "hasRead"
				}]
			},
			closeModal: { //是否点击遮罩关闭iMessage
				type: Boolean,
				default: false
			}
		},
		watch: {
			visible() {
				this.isShow = this.visible;
			},
			isShow() {
				this.$emit("dialogListener", this.isShow);
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 30000
			}
		},
		created() {
			this.isShow = this.visible;
		},
		methods: {
			render(options) {
				Object.assign(this.$props, options);
				this.isShow = true;
			},
			clickBtn(item) {
				this.$emit("onHandle", item);
			},
			//是否点击遮罩关闭messageBox
			closeOnClick() {
				if(this.closeModal) {
					this.isShow = false;
				}
			}
		}
	}
