//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		model: {
			prop: '_filterList',
			event: 'valueListener'
		},
		props: {
			_filterList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		watch: {
			_filterList() {
				this.filterList = this._filterList;
			},
			filterList() {
				this.$emit('valueListener', this.filterList)
			}
		},
		data() {
			return {
				filterList: [],
				isShowPopup: false
			}
		},
		created() {
			this.filterList = this._filterList;
		},
		methods: {
			//是否打开筛选
			showPopup(val) {
				this.isShowPopup = val;
			},
			//选择会议状态
			onSearch(item) {
				this.close();
				this.filterList.map((ele) => {
					ele.isActive = false;
				})
				item.isActive = true;
				this.$emit('onSearch', item);
			},
			//关闭dialog
			close() {
				this.isShowPopup = false;
			}
		}
	}
