//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	//v-model绑值注意避免在子组件对值直接修改
	//vue2.0不允许对props参数直接修改，应通知父组件进行修改
	/*
		该组件采用mint-popup本身存在modal；
		但是mintui popup多个同时出现时都会只有一个modal层
		所以出现层级错乱问题，这时我们自定义个一个遮罩层
	* */
	import Picker from 'vux/src/components/picker';
	export default {
		components: {
			Picker
		},
		model: {
			prop: '_value',
			event: "valueListener"
		},
		props: {
			_value: { //picker已经选中的值
				type: String,
				default: ''
			},
			dataList: { //picker选择列表
				type: Array,
				default: () => {
					return ['请选择'];
				}
			},
			columns: { //选择项列数，当大于1列时，联级效果请参考vux官方文档picker组件说明
				type: Number,
				default: 1
			},
			title: { //title
				type: String,
				default: '请选择'
			},
			modal: { //是否启用其定义遮罩层
				type: Boolean,
				default: false // true == 自定义遮罩层  |  false == 采用mint-popup 自带的modal遮罩
			}
		},
		watch: {
			_value() {
				this.value = this._value.split('-');
			},
			value() {
				this.$emit("valueListener", this.value.join('-'))
			}
		},
		data() {
			return {
				isShowPopup: false,
				value: []
			}
		},
		created() {
			this.value = this._value.split('-');
		},
		methods: {
			showPicker(val) {
				this.isShowPopup = val;
			},
			//确定：需要获取什么参数待续...
			confirm() {
				this.isShowPopup = false;
				this.$emit('confirm', this.value);
			},
			//取消：需要获取什么参数待续...
			clear() {
				this.isShowPopup = false;
				this.value = [];
				this.$emit('cancel', "清空");
			}
		}
	}
