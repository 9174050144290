//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import iHeader from "../iHeader";
	import { commonService } from "../../core/service/service.js";
	const getAlphabet = function() {
		var arr = [];
		for(var i = 65; i < 91; i++) {
			arr.push(String.fromCharCode(i));
		}
		return arr;
	}
	export default {
		components: {
			iHeader
		},
		props: {
			// 是否显示历史城市
			showHistory: {
				type: Boolean,
				default: true
			},
			// 存储历史城市数组的key值，不同场景建议使用不同key，这样互不干涉
			cityKey: {
				type: String,
				default: "cityList"
			},
			// 是否对上线城市进行过滤【例如辉瑞上线城市条件：在城市的json里存在: itsExtData.tenants == ['pfizer']】
			isOnline: {
				type: Boolean,
				default: false
			},
			// 针对上线城市过滤，需要被过滤的租户个体，考虑到不同站点tenant取值方式不一样，所以你可以传入你的tenant值，不必在此纠结tenant等于谁
			tenant: {
				type: String,
				default: "pfizer"
			},
			// 当你编辑你的历史数据若已经存在城市，那么可以将省市传入，只需txt值，组件就会查询对应省市完整的对象，这样避免一些重要的数据丢失情况发生
			city: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				isShowPopup: false, // 组件的开启和关闭
				alphabet: getAlphabet(), // 锚点的生成
				mark: 'A',
				dataList: [], // 省市列表
				historyList: [], //历史城市
				address: {}
			}
		},
		watch: {
			isShowPopup() {
				if(this.isShowPopup) {
					this.setRouteListeners("iCityGoBack");
					this.$root.$eventHub.$on("iCityGoBack", () => {
						this.iCityGoBack();
						this.$root.$eventHub.$off("iCityGoBack");
					});
				} else {
					this.$emit('close'); //销毁组件
					this.removeRouteListeners("iCityGoBack");
				}
			}
		},
		methods: {
			render(options) {
				Object.assign(this.$props, options);
				this.isShowPopup = true;
				this.loadData();
				this.getAppStorage();
			},
			showPopup() {
				this.isShowPopup = true;
				this.loadData();
				this.getAppStorage();
			},
			onConfirm(item) {
				this.address = item;
				this.$emit('confirm', item);
				this.historyList.forEach((ele, index) => {
					if(ele.txt == this.address.txt) {
						this.historyList.splice(index, 1);
					}
				});
				this.historyList.unshift(this.address);
				this.historyList = this.historyList.slice(0, 5);
				this.setAppStorage(JSON.stringify(this.historyList.slice(0, 5)));
				this.iCityGoBack();
			},
			selectHistoryCity(item) {
				this.onConfirm(item)
			},
			// 历史城市存入
			setAppStorage(dataList) {
				let _this = this;
				this.iJsBridge.call({
					method: "SMDataStorage.setItem",
					postData: {
						[_this.cityKey]: dataList
					}
				});
			},
			// 历史城市取出
			getAppStorage() {
				let _this = this;
				this.iJsBridge.call({
					method: "SMDataStorage.getItem",
					postData: {
						key: [_this.cityKey]
					},
					callback: function(data) {
						_this.historyList = JSON.parse(data[_this.cityKey]);
						_this.addHistoryIcon();
					}
				});
			},
			// 滚动监听
			onScroll(event) {
				var allEle;
				for(var i = 0; i < this.alphabet.length; i++) {
					if(this.alphabet[i] == "#") {
						allEle = document.querySelectorAll(".iCityContentBox #historyBox")[0];
					} else {
						allEle = document.querySelectorAll(`.iCityContentBox #${this.alphabet[i]}`)[0];
					}
					if(allEle && event.target.scrollTop >= allEle.offsetTop) {
						this.mark = this.alphabet[i];
					}
				}
			},
			// 锚点定位
			toTegional(index) {
				this.mark = index;
				let id = index == "#" ? "#historyBox" : "#" + index;
				document.querySelector(id) && document.querySelector(id).scrollIntoView(true);
				if("vibrate" in window.navigator) {
					window.navigator.vibrate(20);
				}
			},
			loadData() {
				this.alphabet = [];
				let params = {
					keyword: "",
					keyCode: "CPC-City",
					lnkVal: ""
				}
				this.getDict({
					params: params,
					onSuccess: function(res) {
						this.dataList = res.content;
						//是否对上线城市进行过滤【例如辉瑞上线城市条件：在城市的json里存在: itsExtData.tenants == ['pfizer']】
						if(this.isOnline) {
							this.dataList = this.dataList.filter(ele => {
								return ele.itsExtData.hasOwnProperty('tenants') && ele.itsExtData.tenants.includes(this.tenant);
							})
						}
						this.dataList.forEach((ele) => {
							ele.select = false;
							ele.py = ele.pyVal.substr(0, 1);
							if(this.city == ele.txt) {
								ele.select = true;
							}
						});
						var allCityPy = this.dataList.map(ele => {
							return ele.py;
						})
						this.alphabet = getAlphabet().filter(ele => {
							return allCityPy.includes(ele);
						})
						this.addHistoryIcon();
						this.mark = this.alphabet[0];
					}.bind(this)
				})
			},
			addHistoryIcon() {
				if(this.historyList.length > 0 && this.showHistory) {
					this.alphabet.unshift("#");
				}
			},
			// 搜索城市
			getDict({
				onSuccess,
				params
			}) {
				commonService.getDict(params).then(res => {
					onSuccess(res);
				})
			},
			iCityGoBack() {
				this.isShowPopup = false;
			}
		}
	}
