//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		model: {
			prop: '_keyword',
			event: "keywordListener"
		},
		props: {
			_keyword: {
				type: String,
				default: ""
			},
			placeholder: {
				type: String,
				default: '请输入'
			},
			moreBtn: {
				type: Boolean,
				default: false
			},
			selectMore: {
				type: Function,
				default: function() { }
			},
			selectInfo: {
				type: String,
				default: ''
			}
		},
		watch: {
			_keyword() {
				this.keyword = this._keyword;
			},
			keyword() {
				this.$emit("keywordListener", this.keyword);
				this.searchIconAnimatin();
			}
		},
		data() {
			return {
				keyword: '',
				rotateZ: 0
			}
		},
		methods: {
			searchIconAnimatin() {
				setTimeout(() => {
					this.rotateZ = 180;
					let searchIcon = document.getElementsByClassName('icon-close')[0];
					searchIcon.style.transition = 'transform 0.4s ease-out';
					searchIcon.style.transform = 'rotateZ(' + this.rotateZ + 'deg)';
					this.rotateZ = 0;
				}, 100);
			},
			resetKeyword() {
				this.keyword = '';
				this.$emit('search', '');
			},
			deleteSearchMore() {
				this.selectInfo = '';
				this.$emit('search')
			},
			search() {
				this.$emit("search", this.keyword);
			},
		}
	}
