//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		name: 'ifield',
		props: {
			label: {
				type: String,
				default: ''
			},
			data: {
				type: [String, Number],
				default: null
			},
			verify: {
				type: Boolean,
				default: false
			},
			hasIcon: {
				type: Boolean,
				default: true
			},
			red: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
