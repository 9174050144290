//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		model: {
			prop: "_isShow",
			event: "isShowListener"
		},
		props: {
			_isShow: {
				type: Boolean,
				default: false
			},
			width: {
				type: Number,
				default: 70
			},
			title: {
				type: String,
				default: "提示"
			},
			confirmTxt: {
				type: String,
				default: "确认"
			},
			cancelTxt: {
				type: String,
				default: "取消"
			},
			closeModal: { //是否点击遮罩关闭iDialog
				type: Boolean,
				default: true
			}
		},
		watch: {
			_isShow() {
				this.isShow = this._isShow;
			},
			isShow() {
				this.$emit("isShowListener", this.isShow)
			}
		},
		data() {
			return {
				isShow: false,
				zIndex: 24000
			}
		},
		created() {
			this.isShow = this._isShow;
		},
		methods: {
			//关闭dialog
			close() {
				this.isShow = false;
			},
			//确认
			confirm() {
				this.$emit("onConfirm");
			},
			//取消
			cancel() {
				this.$emit("onCancel");
				this.close()
			},
			//是否点击遮罩关闭messageBox
			closeOnClick() {
				this.closeModal && this.close();
			}
		}
	}
