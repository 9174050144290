import { smartHttp } from 'smart-core-util'
import { indicator } from 'smart-indicator'
import iToast from '@/icomponents/iToast/plugin.js';
import * as tinyCookie from 'tiny-cookie';

const envGatewayUrl = process.env.VUE_APP_GATEWAY_URL;
const urisGatewayUrl = tinyCookie.get("Uris") && JSON.parse(tinyCookie.get("Uris")).Uris.gateway;
const request = new smartHttp({
    baseURL: urisGatewayUrl || envGatewayUrl,
    alert: iToast,
    // block: indicator.open,  //开启loading
    // unblock: indicator.close,  //关闭loading
    // handle401:function(){
    //
    // }
}).api;



export const service = {
    /**
     * 获取日期配置接口 
     */
    getDateConfig: (params) => {
        return request.post(`/api/tenants/lilly/Check/GetDateConfig`, params);
    }
}